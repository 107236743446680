<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      show-empty
      :fields="fields"
      :items="items"
      :busy="isBusy"
      hover
      :responsive="true"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <CommonEmployeeSummary
          :item="data.item.employee"
        ></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(email)="data">
        {{ data.item.email }}
      </template>

      <template v-slot:cell(role)="data">
        <span
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ extractRole(data.item) }}</span
        >
      </template>

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data" v-if="isSuperAdmin">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="assignEmployeeAdmin(data.item)"
            v-b-tooltip.hover="'Assign Admin Role'"
            v-if="
              !data.item.role.includes('SCHOOL SUPER ADMIN') &&
              data.item.role.includes('Member') &&
              !data.item.role.includes('SCHOOL ADMIN')
            "
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeAdmin(data.item)"
            v-b-tooltip.hover="'Remove Admin Role'"
            v-if="data.item.role.includes('SCHOOL ADMIN')"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->

      <!--     begin:: transfer -->
      <template v-slot:cell(transfers)="data" v-if="isSuperAdmin">
        <div class="d-flex flex-row justify-content-left">
          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="transferSuperAdmin(data.item)"
            v-if="!data.item.role.includes('SCHOOL SUPER ADMIN')"
            v-b-tooltip.hover="'Transfer Super Admin Role'"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Exchange.svg" />
            </span>
          </button>
        </div>
      </template>
      <!--      end:: transfer-->

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No employee registered"
          text-bottom="To employee staff, click at the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
export default {
  name: "EmployeeAdminTable",
  components: {
    AppLoader,
    CommonEmployeeSummary,
    AppEmptyList,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    isSuperAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        {
          key: "name",
          label: this.$t("EDUGENERAL.ROLE.NAME"),
          sortable: true,
        },
        {
          key: "role",
          label: this.$t("EDUGENERAL.ROLE.ROLE"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("EDUGENERAL.ROLE.ADMIN"),
        },
        {
          key: "transfers",
          label: this.$t("EDUGENERAL.ROLE.TRANSFER"),
        },
      ],
    };
  },

  methods: {
    extractRole(item) {
      if (item.role.includes("SCHOOL SUPER ADMIN")) {
        return "Super Admin";
      } else if (item.role.includes("SCHOOL ADMIN")) {
        return "Admin";
      } else {
        return "Member";
      }
    },

    assignEmployeeAdmin(data) {
      console.log("Emit employee details clicked");
      this.$emit("assign", data);
    },

    deleteEmployeeAdmin(data) {
      console.log("Emit employee details clicked");
      this.$emit("delete", data);
    },

    transferSuperAdmin(data) {
      console.log("Emit employee details clicked");
      this.$emit("transfer", data);
    },
  },
};
</script>

<style scoped></style>
